<script setup lang="ts">
import type { NavigationItem } from './types'
import { AuthStatus } from '@authentication'

const { status, signIn } = useAuth()

const navigationsItems: NavigationItem[] = [
  {
    name: 'Login',
    click: signIn,
    icon: 'login',
  },
  {
    name: 'Register',
    click: signIn,
    icon: 'register',
  },
]
</script>

<template>
  <div class="z-[1001]">
    <nav
      class="h-navigation flex items-center justify-between border-b border-b-gray-900 bg-black bg-opacity-80 px-6 backdrop-blur-2xl backdrop-saturate-[180%] dark:bg-opacity-60"
    >
      <div class="flex w-full items-center">
        <!-- Unauthenticated menu -->
        <div
          v-if="status === AuthStatus.Unauthenticated"
          class="hidden gap-2 md:flex"
        >
          <Button
            v-for="item in navigationsItems"
            :key="item.name"
            :to="item.to"
            variant="primary"
            class="translate rounded-lg bg-transparent px-5 py-2 text-white transition-all"
            @click="item.click"
          >
            {{ item.name }}
          </Button>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="postcss" scoped>
.nav-link {
  @apply relative pb-4;
  &:before {
    @apply hover:bg-primary absolute -bottom-0.5 left-0 right-0 h-1 content-[''];
  }
}
.router-link-active {
  @apply text-primary;
  &:before {
    @apply bg-primary;
  }
}
</style>
components/Navigation/navigationItems
